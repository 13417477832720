import React, { useState, useEffect } from "react";
import notifSuccessIcon from "../assets/img/notification/notifSuccess.svg";
import notifFailedIcon from "../assets/img/notification/notifFailed.svg";
import MobileNotifSuccessIcon from "../assets/img/notification/mobileNotifSuccess.svg";
import MobileNotifFailedIcon from "../assets/img/notification/mobileNotifFailed.svg";

const Notification = (props) => {
  const { title, message, success } = props;
  const [width, setWidth] = useState(0);
  const updateWidth = () => {
    setWidth(window.innerWidth < 520);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });
  const getIcon = () => {
    if (success) {
      if (width) {
        return MobileNotifSuccessIcon;
      } else {
        return notifSuccessIcon;
      }
    } else {
      if (width) {
        return MobileNotifFailedIcon;
      } else {
        return notifFailedIcon;
      }
    }
  };
  return (
    <div
      className={success ? "notif-container-success" : "notif-container-failed"}
    >
      <div className="notif-status">
        <img src={getIcon()} />
        <p>{title}</p>
      </div>
      <p className="notif-msg"> {message}</p>
    </div>
  );
};

export const NotificationComponent = (title, message, success) => ({
  position: "tr",
  autoDismiss: 5,
  dismissible: "button",
  children: <Notification title={title} message={message} success={success} />
});
