import React from "react";

const Audios = React.lazy(() =>
  import("../../modules/employee/mediaLibrary/containers/Audios")
);
const Videos = React.lazy(() =>
  import("../../modules/employee/mediaLibrary/containers/Videos")
);
const VideoConsult = React.lazy(() =>
  import("../../modules/employee/mediaLibrary/containers/VideoConsult")
);

const Articles = React.lazy(() =>
  import("../../modules/employee/mediaLibrary/containers/Articles")
);

export const audios = {
  path: "/mediaLibrary/audios",
  component: Audios,
};
export const videos = {
  path: "/mediaLibrary/videos",
  component: Videos,
};

export const videoConsult = {
  path: "/mediaLibrary/video/:id",
  component: VideoConsult,
};

export const specificvideoConsult = {
  path: "/mediaLibrary/specificVideo/:id",
  component: VideoConsult,
};

export const articles = {
  path: "/mediaLibrary/articles",
  component: Articles,
};
