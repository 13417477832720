import { ACTIONS } from "../constants";


export default (state
                    = { questionnaireEmployee: {}, isLoading: false, error: null, questionnaires: [], messages:[]}, action) => {
  const { payload, type } = action
  switch (type) {
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT: {
      return { ...state, questionnaireEmployee: {}, isLoadingRecord: true, error: null}
    }
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED: {
      return { ...state, questionnaireEmployee: payload, error: null}
    }
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED: {
      return { ...state, questionnaireEmployee: {}, error: null}
    }
    case ACTIONS.FETCH_MESSAGES_INIT: {
      return { ...state, messages: [] }
    }
    case ACTIONS.FETCH_MESSAGES_SUCCEDED: {
      return { ...state,  messages: payload.conversations}
    }
    case ACTIONS.FETCH_MESSAGES_FAILED: {
      return { ...state, messages: []}
    }
    default: {
      return state
    }
  }
}