import { ACTIONS } from "./../constants";

export default (
  state = {
    questionnaireEmployee: {},
    questionnaires: [],
    medicalRecord: {},
    isLoading: false,
    error: null,
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT: {
      return {
        ...state,
        questionnaireEmployee: {},
        isLoadingRecord: true,
        error: null,
      };
    }
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED: {
      return { ...state, questionnaireEmployee: payload, error: null };
    }
    case ACTIONS.RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED: {
      return { ...state, questionnaireEmployee: {}, error: null };
    }
    case ACTIONS.GET_QUESTIONNAIRES_INIT: {
      return { ...state, questionnaires: [], medicalRecord: {} };
    }
    case ACTIONS.GET_QUESTIONNAIRES_SUCCEDED: {
      return {
        ...state,
        questionnaires: payload.questionnaires,
        medicalRecord: payload.medicalRecord,
      };
    }
    case ACTIONS.GET_QUESTIONNAIRES_FAILED: {
      return { ...state, questionnaires: [], medicalRecord: {} };
    }
    default: {
      return state;
    }
  }
};
